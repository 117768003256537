<template>
  <div>
    <div class="card-toolbar mb-5">
      <button class="btn btn-outline-primary font-weight-bolder ml-5" @click="openAdvancedSearch">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>
    <!--begin::customer-->
    <div v-if="showAdvancedSearch" class="card card-custom mb-5">
      <div class="card-body">
        <!-- Filter -->
        <div v-if="showAdvancedSearch" class="m-form m-form--fit m--margin-bottom-20">
          <div class="row pb-5">
            <div class="col-lg-6 mt-2 mb-2">
              <label>{{ $t('payment_sales_invoices.type') }}</label>

              <select id="type_type" v-model="filters.type_type" class="custom-select" name="">
                <option value="SalesInvoice">{{ $t('payment_sales_invoices.sales_invoice') }}</option>
                <option value="SalesInvoiceRefund">{{ $t('payment_sales_invoices.sales_refund') }}</option>
                <option value="SalesCredit">{{ $t('payment_sales_invoices.sales_credit') }}</option>

              </select>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="invoice_code">{{ $t('payment_sales_invoices.invoice_code') }}</label>
              <input id="invoice_code" v-model="filters.invoice_code" class="form-control" type="text">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('payment_sales_invoices.from_date') }}</label>
              <input id="from_date" v-model="filters.from_date" class="form-control" type="date">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('payment_sales_invoices.to_date') }}</label>
              <input id="to_date" v-model="filters.to_date" class="form-control" type="date">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_amount">{{ $t('payment_sales_invoices.from_amount') }}</label>
              <input id="from_amount" v-model="filters.from_amount" class="form-control" type="number">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_amount">{{ $t('payment_sales_invoices.to_amount') }}</label>
              <input id="to_amount" v-model="filters.to_amount" class="form-control" type="number">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="customer_id">{{ $t('payment_sales_invoices.customer') }}</label>
              <!--                                <select name="" id="customer_id" v-model="filters.customer_id" class="custom-select">-->
              <!--                                    <option v-for="row in customers" :value="row.id" :key="row.id">-->
              <!--                                        {{ row.fullname }}-->
              <!--                                    </option>-->
              <!--                                </select>-->
              <multiselect
                  id="customer_id"
                  v-model="customer"
                  :multiple="false"
                  :options="customers"
                  :placeholder="$t('payment_sales_invoices.customer')"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :taggable="false"
                  label="fullname"
                  track-by="id"
                  @search-change="getCustomers($event)">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('payment_sales_invoices.collected_by') }}</label>
              <multiselect
                  v-model="collected_by"
                  :multiple="false"
                  :options="users"
                  :placeholder="$t('payment_sales_invoices.collected_by')"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :taggable="false"
                  label="name"
                  track-by="id"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>

            <div class="col-lg-6  mt-2 mb-2">
              <label>{{ $t('payment_sales_invoices.payment_method') }}</label>
              <select id="payment_method" v-model="filters.payment_method" class="custom-select" name="">
                <option v-for="row in payment_methods" :key="row.id" :value="row.id">{{ row.title }}</option>
              </select>
            </div>
            <div class="col-lg-6 mt-2 mb-2">
              <label>{{ $t('payment_sales_invoices.payment_status') }}</label>
              <select id="payment_status" v-model="filters.payment_status" class="custom-select" name="">
                <option v-for="row in payment_status_list" :key="row.id" :value="row.id">{{ row.title }}</option>
              </select>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('payment_sales_invoices.invoiced_by') }}</label>

              <multiselect
                  v-model="invoiced_by"
                  :multiple="false"
                  :options="users"
                  :placeholder="$t('payment_sales_invoices.invoiced_by')"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :taggable="false"
                  label="name"
                  track-by="id"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button id="m_search" class="btn btn-primary mt-auto mr-2" type="button" @click="doFilter">
                <span><i class="fas fa-search"></i>
                  <span>{{ $t('search') }}</span>
                </span>
              </button>
              <button id="m_reset" class="btn btn-danger mt-auto mr-2" type="button" @click="resetFilter">
                <span><i class="fas fa-trash-restore"></i>
                  <span>{{ $t('reset_search') }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <!-- End Filter -->
      </div>
    </div>
    <div class="card card-custom">
      <div class="card-body">
        <button class="btn btn-primary font-weight-bolder" v-if="isSystemUser" :class="{
          'btn-style': isTableLoading,
        }" @click="updatePaidAmount">
          {{ $t('update_paid_amount') }}
        </button>
        <v-server-table ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">
          <template slot="#" slot-scope="props">
            <span>{{ props.index }}</span>
          </template>
          <template slot="client_info" slot-scope="props">
            <div>
              <div>
                <span>
                  {{ $t('payment_sales_invoices.customer_code') }}:
                </span>
                <span>{{ props.row.customer_code }}</span>
              </div>
              <div>
                <span>
                  {{ $t('payment_sales_invoices.customer_name') }}:
                </span>
                <span>{{ props.row.customer_fullname }}</span>
              </div>
              <div><span>{{ $t('payment_sales_invoices.collected_by') }}: </span>
                <span>{{ props.row.collected_user_name }}</span>
              </div>
              <div>
                <span>{{ $t('payment_sales_invoices.type') }}: </span>
                <span v-if="props.row.type_type_label == 'SalesInvoice'">{{
                    $t('payment_sales_invoices.sales_invoice')
                  }}</span>
                <span v-else-if="props.row.type_type_label == 'SalesCredit'">{{
                    $t('payment_sales_invoices.sales_credit')
                  }}</span>
                <span v-else-if="props.row.type_type_label == 'SalesInvoiceRefund'">{{
                    $t('payment_sales_invoices.sales_refund')
                  }}</span>
                <span v-else-if="props.row.type_type_label == 'GeneralSales'">{{
                    $t('payment_sales_invoices.general_sales')
                  }}</span>
                <span v-else-if="props.row.type_type_label == 'GeneralSalesInvoiceRefund'">{{
                    $t('payment_sales_invoices.general_sales_refund')
                  }}</span>
              </div>
              <div v-if="props.row.invoice_code">
                <span>{{ $t('payment_sales_invoices.invoice_code') }}: </span>
                <span>{{ props.row.invoice_code }}</span>
              </div>
            </div>
          </template>
          <template slot="payment_details" slot-scope="props">
            <div v-if="props.row.code">
              <span>{{ $t('payment_sales_invoices.code') }}: </span><span>{{ props.row.code }}</span>
            </div>
            <div>
              <span>{{ props.row.amount }} {{ props.row.currency_code }}</span>
            </div>
            <!--            <div>-->
            <!--              <span>({{ props.row.payment_status_details }})</span>-->
            <!--            </div>-->
            <div>
              <span>{{ $t('payment_sales_invoices.payment_method') }}: </span><span>{{
                props.row.payment_method_name
              }}</span>
            </div>
            <div><span>{{ $t('payment_sales_invoices.payment_date') }}: </span><span>{{ props.row.payment_date }}</span>
            </div>
            <div><span>({{ props.row.adjustment_type_name }})</span></div>
            <div v-if="props.row.attachment_url">
              <a :href="props.row.attachment_url" target="_blank">{{ $t('payment_sales_invoices.attachment') }}</a>
            </div>
          </template>
          <template slot="status" slot-scope="props">
            <button v-if="$can('payment_sales_invoices.change_status')"
                    class="btn btn-secondary m-btn m-btn--icon w-auto"
                    @click="changeStatus(props.row.id, props.row.payment_status)">
              {{ $t('change_status') }} ({{ props.row.payment_status_name }})
            </button>
            <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.payment_status_name }})
            </button>
          </template>
          <template slot="actions" slot-scope="props">
            <!--             -->
            <v-btn v-if="$can('payment_sales_invoices.update') && props.row.payment_status != 2" v-b-tooltip.hover :title="$t('edit')"
                   :to="`/sales/payment_sales_invoices/edit/${props.row.id}/${props.row.url_type}`" color="pink"
                   icon>
              <v-icon class="mr-2 text-info" small>mdi-pencil</v-icon>
            </v-btn>
            <!--              -->
            <v-icon v-if="$can('payment_sales_invoices.delete') && props.row.payment_status != 2" v-b-tooltip.hover :title="$t('delete')" class="text-danger"
                    small
                    @click="deleteItem(props.row)">mdi-delete
            </v-icon>
            <div>
              <b-dropdown :text="$t('more_actions')" class="m-2" right variant="outline-primary btn-sm">

                <div v-if="$can('payment_sales_invoices.printing_export_pdf_financial')"
                     class="dropdown-item d-flex justify-content-between">
                  <label>{{ $t('payment_sales_invoices.financial_payment') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link v-b-tooltip.hover :title="$t('print')" :to="`/print/payment-invoice/${props.row.id}/print`" class="ml-3 mr-3"
                                 target="_blank">
                      <i class="fas fa-print"></i>
                    </router-link>
                    <!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/payment-invoice/${props.row.id}/pdf`">-->
                    <!--                      <i class="fas fa-file-pdf"></i>-->
                    <!--                    </router-link>-->
                  </div>
                </div>
                <router-link v-if="$can('payment_sales_invoices.details')" :to="`/sales/payment_sales_invoices/payment-sales-details/${props.row.id}`"
                             class="dropdown-item">
                  {{ $t('payment_sales_details.payment_details') }}
                </router-link>

                <b-dropdown-item @click="showDetails(props.row.id)">{{
                    $t('payment_sales_invoices.details')
                  }}
                </b-dropdown-item>
                <router-link v-if="$can('payment_sales_invoices.send_mail')" :to="`/settings/custom/email/${props.row.id}/PaymentSales`"
                             class="dropdown-item">
                  {{ $t('send_mail') }}
                </router-link>
              </b-dropdown>
            </div>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="status-modal" :title="$t('change_status')" hide-footer size="md">
      <change-status-form :current-id="innerId"
                          :current-status="statusId"
                          :show-confirm-message="true"
                          :status-list="payment_status_list"
                          :url="routeChangeStatus"
                          @hide-modal="hideModal()"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>
  </div>
</template>


<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";
import {mapGetters} from "vuex";

export default {
  name: "index-payment_sales_invoices",
  components: {'change-status-form': ChangeStatusFrom,},
  data() {
    return {
      mainRoute: 'sales/payment_sales_invoices',
      routeChangeStatus: 'sales/payment_sales_invoice/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,
      isTableLoading: false,
      filter: {
        sortBy: 'id',
      },
      filters: {
        invoice_code: null,
        from_date: null,
        to_date: null,
        from_amount: null,
        to_amount: null,
        customer_id: null,
        collected_user_id: null,
        payment_method: null,
        payment_status: null,
        invoiced_by_id: null,
        type_type: null,
        filter_type: 'sales',
      },
      columns: ['#', 'client_info', 'payment_details', 'status', 'actions'],
      data: [],
      payment_methods: [],
      payment_status_list: [],
      users: [],
      customer: null,
      customers: [],
      collected_by: null,
      invoiced_by: null,
      innerId: null,
      statusId: null,

    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function(){
      return this.currentUserPersonalInfo.is_system_user
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          client_info: that.$t('payment_sales_invoices.client_info'),
          payment_details: that.$t('payment_sales_invoices.payment_details'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            perPage: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          that.isTableLoading = true;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },
    collected_by: function (val) {
      if (val) {
        this.filters.collected_user_id = val.id;
      } else {
        this.filters.collected_user_id = null;
      }
    },
    invoiced_by: function (val) {
      if (val) {
        this.filters.invoiced_by_id = val.id;
      } else {
        this.filters.invoiced_by_id = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.payment_sales_invoices")}]);
    this.getPaymentMethods();
    this.getPaymentStatus();
    // this.getCustomers();
    // this.getUsers();
  },
  methods: {

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.invoice_code = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.from_amount = null;
      this.filters.to_amount = null;
      this.filters.customer_id = null;
      this.filters.collected_user_id = null;
      this.filters.payment_method = null;
      this.filters.payment_status = null;
      this.filters.invoiced_by_id = null;
      this.filters.type_type = null;
      this.customer = null;
      this.collected_by = null;
      this.invoiced_by = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getPaymentStatus() {
      ApiService.get(this.mainRouteDependency + "/payment_status").then((response) => {
        this.payment_status_list = response.data.data;
      });
    },

    getCustomers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      }
    },

    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    showDetails(id) {
      this.$router.push({name: 'payment_sales_invoices.details', params: {id: id}});
    },
    updatePaidAmount() {
      this.$confirmAlert(this.$t('do_you_want_update_paid_amount'), this.updatePaid);
    },
    updatePaid() {
        ApiService.patch(`sales/payment_sales_invoice/calculate_paid_amount`).then((response) => {
          this.$successAlert(response.data.message);
          this.$refs.table.refresh();
        }).catch((error) => {
          this.$errorAlert(error)
        })
    },
    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
  },
};
</script>

s
<style scoped>
  .btn-style {
    transform: translateY(35px);
    margin-top: -35px;
    position: relative;
    z-index: 22;
  }
  </style>
